
const ResetPassword = () => {
    return(
        <>
<main className="AuthSection">
  <div className="bg-primary pt-5 pb-0 position-relative">
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-sm-10 col-md-8 col-lg-6 position-relative z-index-1">
          <div className="card card-body p-4 p-sm-5 mt-sm-n5 mb-n5">
            <h2 className="h1 mb-2">Reset password?</h2>
            <p>Enter the new password.</p>
            <form class="mt-3">
                <div class="mb-3">
                    <div class="input-group input-group-lg">
                        <input class="form-control fakepassword" type="password" id="psw-input" placeholder="Enter your password" />
                        <span class="input-group-text p-0">
                            <i class="fakepasswordicon fa-solid fa-eye-slash cursor-pointer p-2 w-40px"></i>
                        </span>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="input-group input-group-lg">
                        <input class="form-control fakepassword" type="password" id="psw-input" placeholder="Enter your confirm password" />
                        <span class="input-group-text p-0">
                            <i class="fakepasswordicon fa-solid fa-eye-slash cursor-pointer p-2 w-40px"></i>
                        </span>
                    </div>
                </div>
                <div class="mb-3">
                <p>Back to <a href="/login">Sign in</a></p>
                </div>
                <div class="d-grid"><button type="submit" class="btn btn-lg btn-primary">Submit</button></div>
                <p class="mb-0 mt-3">©2022 <a target="_blank" href="https://www.webestica.com/">Webestica.</a> All rights reserved</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

        </>
    )
}

export default ResetPassword;