import Header from '../layout/Header';
import LeftSidebar from "./sidebar/LeftSidebar";
import Footer from '../layout/Footer';

const Albums = ()=>{
    return(
        <>
    <Header/>        
<main>
  <div className="container">
    <div className="row g-4">
    <LeftSidebar/>
      <div className="col-md-8 col-lg-6 vstack gap-4">
        <div className="card">
          <div className="card-header d-sm-flex text-center align-items-center justify-content-between border-0 pb-0">
            <h1 className="card-title h4">Photos</h1>
            <a className="btn btn-primary-soft" href="#" data-bs-toggle="modal" data-bs-target="#modalCreateAlbum"> <i className="fa-solid fa-plus pe-1"></i> Create album</a>
          </div>
          <div className="card-body">
            <ul className="nav nav-tabs nav-bottom-line justify-content-center justify-content-md-start">
              <li className="nav-item"> <a className="nav-link active" data-bs-toggle="tab" href="#tab-1"> Photos of you </a> </li>
              <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-2"> Your photos </a> </li>
              <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-3"> Recently added </a> </li>
              <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-4"> Family </a> </li>
              <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-5"> Albums </a> </li>
            </ul>
            <div className="tab-content mb-0 pb-0">
              <div className="tab-pane fade active show" id="tab-1">
                <div className="row g-3">
                  <div className="col-6 col-lg-3">
                    <div className="border border-2 border-dashed h-100 rounded text-center d-flex align-items-center justify-content-center position-relative">
                      <a className="stretched-link" href="#!">
                        <i className="fa-solid fa-camera-retro fs-1"></i>
                        <h6 className="mt-2">Add photo</h6>
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/01.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/01.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit2" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit2">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/02.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/02.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction2" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction2">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction2" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction2">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit3" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit3">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/03.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/03.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction3" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction3">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction3" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction3">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex"> 
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit4" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit4">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/04.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/04.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction4" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction4">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction4" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction4">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit5" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit5">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/05.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/05.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction5" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction5">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction5" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction5">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit6" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit6">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/06.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/06.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction6" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction6">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction6" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction6">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-2">
                <div className="row g-3">
                  <div className="col-6 col-lg-3">
                    <div className="border border-2 border-dashed h-100 rounded text-center d-flex align-items-center justify-content-center position-relative">
                      <a className="stretched-link" href="#!">
                        <i className="fa-solid fa-camera-retro fs-1"></i>
                        <h6 className="mt-2">Add photo</h6>
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit7" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit7">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/01.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/01.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction7" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction7">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction7" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction7">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="postActionEdit2" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="postActionEdit2">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/02.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/02.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction8" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction8">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction8" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction8">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit8" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit8">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/03.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/03.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction9" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction9">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction9" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction9">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="tab-pane fade" id="tab-3">
                <div className="row g-3">
                  <div className="col-6 col-lg-3">
                    <div className="border border-2 border-dashed h-100 rounded text-center d-flex align-items-center justify-content-center position-relative">
                      <a className="stretched-link" href="#!">
                        <i className="fa-solid fa-camera-retro fs-1"></i>
                        <h6 className="mt-2">Add photo</h6>
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit9" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit9">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/01.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/01.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction10" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction10">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction10" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction10">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit10" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit10">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/02.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/02.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction11" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction11">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction11" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction11">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 position-relative">
                    <div className="position-absolute bottom-0 end-0">
                      <div className="dropdown mb-2 me-3">
                        <a href="#" className="icon-sm bg-primary text-white rounded-circle" id="photoActionEdit12" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit12">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-tag fa-fw pe-1"></i> Remove Tag</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person fa-fw pe-1"></i> Make Profile Picture</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-person-bounding-box fa-fw pe-1"></i> Make Cover Photo</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report photo</a></li>
                        </ul>
                      </div>
                    </div>
                    <a href="assets/images/albums/03.jpg" data-gallery="image-popup" data-glightbox="description: .custom-desc2; descPosition: left;">
                      <img className="rounded img-fluid" src="assets/images/albums/03.jpg" alt=""/>
                    </a>
                    <div className="glightbox-desc custom-desc2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar me-2">
                            <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">Lori Ferguson</h6>
                              <span className="nav-item small"> 2hr</span>
                            </div>
                            <p className="mb-0 small">Web Developer at Webestica</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction12" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction12">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                          </ul>
                        </div>
                      </div>
                      <p className="mt-3 mb-0">I'm so privileged to be involved in the @bootstrap hiring process! <a href="#">#internship #inclusivebusiness</a> <a href="#">#internship</a> <a href="#"> #hiring</a> <a href="#">#apply</a> </p>
                      <ul className="nav nav-stack py-3 small">
                        <li className="nav-item">
                          <a className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                        </li>
                        <li className="nav-item dropdown ms-auto">
                          <a className="nav-link mb-0" href="#" id="cardShareAction12" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-reply-fill fa-flip-horizontal pe-1"></i>Share (3)
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction12">
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                          </ul>
                        </li>
                      </ul>
                      <div className="d-flex mb-3">
                        <div className="avatar avatar-xs me-2">
                          <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""/>
                        </div>
                        <form className="position-relative w-100">
                          <textarea className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                        </form>
                      </div>
                      <ul className="comment-wrap list-unstyled ">
                        <li className="comment-item">
                          <div className="d-flex">
                            <div className="avatar avatar-xs">
                              <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/>
                            </div>
                            <div className="ms-2">
                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                <div className="d-flex justify-content-center">
                                  <div className="me-2">
                                    <h6 className="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p className="small mb-0">Removed demands expense account in outward tedious do.</p>
                                  </div>
                                  <small>5hr</small>
                                </div>
                              </div>
                              <ul className="nav nav-divider py-2 small">
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Like (3)</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> Reply</a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#!"> View 5 replies</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade text-center" id="tab-4">
                <div className="my-sm-5 py-sm-5">
                  <i className="display-1 text-muted bi bi-file-earmark-x"> </i>
                  <h4 className="mt-2 mb-3 text-body">No photos or videos</h4>
                  <button className="btn btn-primary-soft btn-sm" data-bs-toggle="modal" data-bs-target="#modalCreateAlbum"> Click here to add </button>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-5">
                <div className="row g-3">
                  <div className="col-6 col-lg-3">
                    <a href="#">
                      <img className="rounded img-fluid" src="assets/images/albums/01.jpg" alt=""/>
                    </a>
                    <div className="hstack mt-3">
                      <div>
                        <h6 className="mb-0"> <a href="#!"> Cover Photos </a> </h6>
                        <a className="text-secondary small" href="#!">5 Items</a>
                      </div>
                      <div className="dropdown ms-auto">
                        <a href="#" className="icon-sm bg-light text-secondary rounded-circle" id="photoActionEdit17" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-three-dots"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="photoActionEdit17">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil fa-fw pe-1"></i> Edit</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report album</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-3">
                    <a href="#">
                      <img className="rounded img-fluid" src="assets/images/albums/02.jpg" alt=""/>
                    </a>
                    <div className="hstack mt-3">
                      <div>
                        <h6 className="mb-0"> <a href="#!"> Profile pictures </a> </h6>
                        <a className="text-secondary small" href="#!">20 Items</a>
                      </div>
                      <div className="dropdown ms-auto">
                        <a href="#" className="icon-sm bg-light text-secondary rounded-circle" id="albumActionSetting2" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-three-dots"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="albumActionSetting2">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil fa-fw pe-1"></i> Edit</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report album</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3">
                    <a href="#">
                      <img className="rounded img-fluid" src="assets/images/albums/03.jpg" alt=""/>
                    </a>
                    <div className="hstack mt-3">
                      <div>
                        <h6 className="mb-0"> <a href="#!"> Untitled pictures </a> </h6>
                        <a className="text-secondary small" href="#!">12 Items</a>
                      </div>
                      <div className="dropdown ms-auto">
                        <a href="#" className="icon-sm bg-light text-secondary rounded-circle" id="albumActionSetting3" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-three-dots"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="albumActionSetting3">
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil fa-fw pe-1"></i> Edit</a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-download fa-fw pe-1"></i> Download </a></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-1"></i> Report album</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</main>


<div class="modal fade" id="modalCreateAlbum" tabindex="-1" aria-labelledby="modalLabelCreateAlbum" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalLabelCreateAlbum">Create album</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <label class="form-label">Album name</label>
            <input type="email" class="form-control" placeholder="Add album name here"/>
          </div>
          <div class="mb-3">
            <label class="form-label">Select audience</label>
            <select class="form-select js-choice rounded" data-remove-item-button="true" data-placeholder="false">
              <option value="PB">Public</option>
              <option value="FR">Friends</option>
              <option value="SF">Specific friends</option>
              <option value="OM">Only me</option>
              <option value="CM">Custom</option>
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label">Upload Photos or Videos</label>
            <div class="dropzone dropzone-default card shadow-none" data-dropzone='{"maxFiles":2}'>
              <div class="dz-message">
                <i class="fa-solid fa-folder-open display-3"></i>
                <p>Drop image here or click to upload.</p>
              </div>
            </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success-soft">Create now</button>
      </div>
    </div>
  </div>
</div>


<Footer/>
        </>
    )    
}

export default Albums;
