const ForgotPassword = () =>{
    return(
        <>
<main className="AuthSection">
  <div className="bg-primary pt-5 pb-0 position-relative">
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-sm-10 col-md-8 col-lg-6 position-relative z-index-1">
          <div className="card card-body p-4 p-sm-5 mt-sm-n5 mb-n5">
            <h2 className="h1 mb-2">Forgot password?</h2>
            <p>Enter the email address associated with account.</p>
            <form class="mt-3">
                <div class="mb-3">
                <div class="input-group input-group-lg">
                    <input class="form-control fakepassword" type="email" id="psw-input" placeholder="Enter your email" />
                </div>
                <div id="pswmeter" class="mt-2 password-strength-meter"><div class="password-strength-meter-score"></div></div>
                <div class="d-flex mt-1">
                    <div id="pswmeter-message" class="rounded">Write your email...</div>
                    <div class="ms-auto">
                        <i class="bi bi-info-circle ps-1" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Include at least one uppercase, one lowercase, one special character, one number and 8 characters long." data-bs-original-title="" title=""></i>
                    </div>
                </div>
                </div>
                <div class="mb-3">
                <p>Back to <a href="/login">Sign in</a></p>
                </div>
                <div class="d-grid"><button type="submit" class="btn btn-lg btn-primary">Submit</button></div>
                <p class="mb-0 mt-3">©2022 <a target="_blank" href="https://www.webestica.com/">Webestica.</a> All rights reserved</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

        </>
    )
}

export default ForgotPassword;