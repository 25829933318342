const Signup = ()=>{
    return(
        <>
<main className="AuthSection">
  <div className="bg-primary pt-5 pb-0 position-relative">
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-sm-10 col-md-8 col-lg-6 position-relative z-index-1">
          <div className="card card-body p-4 p-sm-5 mt-sm-n5 mb-n5">
            <h2 className="h1 mb-2">Sign Up</h2>
            <p>Have an account?<a href="/Login"> Click here to Login</a></p>
            <form className="mt-4">
              <div className="mb-3 position-relative input-group-lg">
                <input type="text" className="form-control" placeholder="Enter name"/>
              </div>
              <div className="mb-3 position-relative input-group-lg">
                <input type="email" className="form-control" placeholder="Enter email"/>
              </div>
              <div className="mb-3">
                <div className="input-group input-group-lg">
                  <input className="form-control fakepassword" type="password" id="psw-input" placeholder="Enter new password"/>
                  <span className="input-group-text p-0">
                    <i className="fakepasswordicon fa-solid fa-eye-slash cursor-pointer p-2 w-40px"></i>
                  </span>
                </div>
                <div id="pswmeter" className="mt-2 password-strength-meter">
                    <div className="password-strength-meter-score"></div>
                </div>
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-lg btn-primary-soft">Login</button>
              </div>
              <p className="mb-0 mt-3">©2022 <a target="_blank" href="/">Webestica.</a> All rights reserved</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
        </>
    )
}


export default Signup;