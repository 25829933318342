import LeftSidebar from "./sidebar/LeftSidebar";
import RightSidebar from "./sidebar/RightSidebar";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
// const  Celebration = () => {
const Celebration = () => {
    return(
        <>
<Header />     
<main>
  <div className="container">
    <div className="row g-4">    
    <LeftSidebar />      
      <div className="col-md-8 col-lg-6 vstack gap-4">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h1 className="h4 card-title">Celebration</h1>
            </div>
            <div className="card-body">
              <div className="d-flex mb-3">
                <div className="avatar avatar-lg status-online me-2">
                  <a href="#!"><img className="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt=""/></a>
                </div>
                <div className="ms-2 w-100">
                  <div className="d-sm-flex justify-content-between">
                    <h6><a href="#!">Lori Ferguson </a></h6>
                    <p className="small ms-sm-2 mb-0 mb-sm-2">Today is her birthday</p>
                    <div className="dropdown ms-auto">
                      <a href="#" className="text-secondary" id="bdayActionEdit" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-three-dots"></i>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bdayActionEdit">
                        <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                        <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-1"></i> Turn off notification </a></li>
                        <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-1"></i> Mute Amanda Read </a></li>
                      </ul>
                    </div>
                </div>
                <div className="position-relative w-100">
                  <textarea className="form-control pe-4" rows="1" placeholder="Happy birthday dear.."></textarea>
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="d-flex mb-3">
              <div className="avatar avatar-lg me-2">
                <div className="avatar-img rounded-circle bg-danger bg-opacity-10 text-danger"><span className="position-absolute top-50 start-50 translate-middle fw-bold">BV</span></div>
              </div>
              <div className="ms-2 w-100">
                <div className="d-sm-flex justify-content-between">
                    <h6 className="mb-0"><a href="#!">Billy Vasquez </a></h6>
                    <p className="small ms-sm-2 mb-0 mb-sm-2">Today is her birthday</p>
                  <div className="dropdown ms-auto">
                    <a href="#" className="text-secondary" id="bdayActionEdit2" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="bi bi-three-dots"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bdayActionEdit2">
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-1"></i> Turn off notification </a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-1"></i> Mute Amanda Read </a></li>
                    </ul>
                  </div>
                </div>
                <div className="position-relative w-100">
                  <textarea className="form-control pe-4" rows="1" placeholder="Birthday wish here.."></textarea>
                </div>
              </div>
            </div>
            <hr className="my-4"/>
            <div className="d-flex mb-3">
              <div className="avatar avatar-story avatar-lg me-2">
                <a href="#!"><img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""/></a>
              </div>
              <div className="ms-2 w-100">
                <div className="d-sm-flex justify-content-between">
                  <p className="mb-0 mb-sm-2">Congratulate <a className="h6" href="#!">Amanda Reed </a> for 3 years at <a className="h6" href="#">Bootstrap - Front-end framework</a>  </p>
                  <div className="dropdown ms-auto">
                    <a href="#" className="text-secondary" id="bdayActionEdit3" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="bi bi-three-dots"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bdayActionEdit3">
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-1"></i> Turn off notification </a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-1"></i> Mute Amanda Read </a></li>
                    </ul>
                  </div>
                </div>
                <div className="position-relative w-100">
                  <textarea className="form-control pe-4" rows="1" placeholder="Congratulate"></textarea>
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="d-flex mb-3">
              <div className="avatar avatar-lg me-2">
                <a href="#!"><img className="avatar-img rounded-circle" src="assets/images/avatar/06.jpg" alt=""/></a>
              </div>
              <div className="ms-2 w-100">
                <div className="d-sm-flex justify-content-between">
                  <p>Judy Nguyen and 3 other connections are attending <strong> WordCamps San Francisco.</strong></p>
                  <div className="dropdown ms-auto">
                    <a href="#" className="text-secondary" id="bdayActionEdit4" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="bi bi-three-dots"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bdayActionEdit4">
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-1"></i> Turn off notification </a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-1"></i> Mute Amanda Read </a></li>
                    </ul>
                  </div>
                </div>
                <a href="event-details.html" className="btn btn-sm btn-primary-soft">View event </a>
              </div>
            </div>
            <hr className="my-4" />
            <div className="d-flex mb-3">
              <div className="avatar avatar-lg me-2">
                <a href="#!"><img className="avatar-img rounded-circle" src="assets/images/avatar/07.jpg" alt=""/></a>
              </div>
              <div className="ms-2 w-100">
                <div className="d-sm-flex justify-content-between">
                    <h6 className="mb-0"><a href="#!">Samuel Bishop </a></h6>
                    <p className="small ms-sm-2 mb-0 mb-sm-2">Birthday is in 2 january</p>
                  <div className="dropdown ms-auto">
                    <a href="#" className="text-secondary" id="bdayActionEdit5" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="bi bi-three-dots"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bdayActionEdit5">
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-1"></i> Turn off notification </a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-1"></i> Mute Amanda Read </a></li>
                    </ul>
                  </div>
                </div>
                <div className="position-relative w-100">
                  <textarea className="form-control pe-4" rows="1" placeholder="Birthday wish here.."></textarea>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h5 className="card-title">Upcoming Celebration</h5>
            </div>
            <div className="card-body">
              <div className="d-flex mb-3">
                <div className="avatar avatar-lg status-online me-2">
                  <a href="#!"><img className="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt=""/></a>
                </div>
                <div className="ms-2 w-100">
                  <div className="d-sm-flex justify-content-between">
                    <h6><a href="#!">Jacqueline Miller </a></h6>
                    <p className="small ms-sm-2 mb-0 mb-sm-2">Birthday is in 10 march</p>
                    <div className="dropdown ms-auto">
                      <a href="#" className="text-secondary" id="bdayActionEdit6" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-three-dots"></i>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bdayActionEdit6">
                        <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                        <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-1"></i> Turn off notification </a></li>
                        <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-1"></i> Mute Amanda Read </a></li>
                      </ul>
                    </div>
                </div>
                <div className="position-relative w-100">
                  <textarea className="form-control pe-4" rows="1" placeholder="Happy birthday dear.."></textarea>
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="d-flex mb-3">
              <div className="avatar avatar-lg me-2">
                <div className="avatar-img rounded-circle bg-success bg-opacity-10 text-success"><span className="position-absolute top-50 start-50 translate-middle fw-bold text-center lh-1">22 <br/> Feb </span></div>
              </div>
              <div className="ms-2 w-100">
                <div className="d-sm-flex justify-content-between">
                  <div>
                    <a className="h6" href="#!">International Conference on Cyber Law, Cybercrime &amp; Cyber Security</a>
                    <p className="d-block small"> <i className="bi bi-geo-alt-fill"></i> 19 School Lane, London, United Kingdom</p>
                  </div>
                  <div className="dropdown ms-auto">
                    <a href="#" className="text-secondary" id="bdayActionEdit7" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="bi bi-three-dots"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bdayActionEdit7">
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-1"></i> Turn off notification </a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-1"></i> Mute Amanda Read </a></li>
                    </ul>
                  </div>
                </div>
                <a href="event-details.html" className="btn btn-sm btn-primary-soft">Join now </a>
              </div>
            </div>
            <hr className="my-4"/>
            <div className="d-flex mb-3">
              <div className="avatar avatar-lg me-2">
                <a href="#!"><img className="avatar-img rounded-circle" src="assets/images/avatar/09.jpg" alt=""/></a>
              </div>
              <div className="ms-2 w-100">
                <div className="d-sm-flex justify-content-between">
                  <p className="mb-0 mb-sm-2">Congratulate <a className="h6" href="#!">Amanda Reed </a> for 3 years at <a className="h6" href="#">Bootstrap - Front-end framework</a>  </p>
                  <div className="dropdown ms-auto">
                    <a href="#" className="text-secondary" id="bdayActionEdit8" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="bi bi-three-dots"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bdayActionEdit8">
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-1"></i> Turn off notification </a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-1"></i> Mute Amanda Read </a></li>
                    </ul>
                  </div>
                </div>
                <div className="position-relative w-100">
                  <textarea className="form-control pe-4" rows="1" placeholder="Congratulate"></textarea>
                </div>
              </div>
            </div>
            <hr className="my-4"/>
            <div className="d-flex mb-3">
              <div className="avatar avatar-story avatar-lg me-2">
                <a href="#!"><img className="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt=""/></a>
              </div>
              <div className="ms-2 w-100">
                <div className="d-sm-flex justify-content-between">
                  <p>Joan Wallace and 5 other connections are attending <strong> WordCamps New York.</strong></p>
                  <div className="dropdown ms-auto">
                    <a href="#" className="text-secondary" id="bdayActionEdit9" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="bi bi-three-dots"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bdayActionEdit9">
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-trash fa-fw pe-1"></i> Delete</a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-1"></i> Turn off notification </a></li>
                      <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-1"></i> Mute Amanda Read </a></li>
                    </ul>
                  </div>
                </div>
                <a href="event-details.html" className="btn btn-sm btn-primary-soft">View event </a>
              </div>
            </div>
          </div>
          </div>
      </div>
    <RightSidebar />     
    </div>
  </div>
</main>

<Footer/>

        </>
    )
}

export default Celebration;