// import { useEffect } from 'react';

import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Profile from './pages/Profile';
import Chat from './pages/Chat';
import Notification from './pages/Notification';
import Albums from './pages/Albums';
import NotFoundPage from './pages/NotFoundPage';
import Celebration from './pages/Celebration';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import './App.css';

// import { tns } from "../node_modules/tiny-slider/src/tiny-slider";
function App() {
  return <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/chat" element={<Chat />}></Route>
          <Route path="/notification" element={<Notification />}></Route>
          <Route path="/albums" element={<Albums />}></Route>
          <Route path="/celebration" element={<Celebration />}></Route>
          <Route path="/forgotPassword" element={<ForgotPassword />}></Route>
          <Route path="/resetPassword" element={<ResetPassword />}></Route>
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
    </BrowserRouter>
}

export default App;
