
const Footer = () => {
    return (
        <>
        <footer className="bg-dark py-3">
            <div className="container">
                <ul className="pagesLink">
                    <li><a href="/">Home</a></li>
                    <li><a href="/login">Login</a></li>
                    <li><a href="/signup">Signup</a></li>
                    <li><a href="/profile">Profile</a></li>
                    <li><a href="/chat">Chat</a></li>
                    <li><a href="/notification">Notification</a></li>
                    <li><a href="/albums">Albums</a></li>
                    <li><a href="/celebration">Celebration</a></li>
                    <li><a href="/forgotPassword">Forgot Password</a></li>
                    <li><a href="/resetPassword">Reset Password</a></li>
                </ul>
            </div>
        </footer>
        </>
    )
}

export default Footer;