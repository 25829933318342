import { useEffect } from 'react';
import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";

const FriendsSlider = () => {
   useEffect(() => {
      const slider = tns({
         container: ".my-slider",
         loop: true,
         items: 1,
         slideBy: "page",
         nav: false,
         autoplay: false,
         speed: 400,
         edgePadding: 10,
         autoplayButtonOutput: false,
         mouseDrag: true,
         lazyload: true,
         controlsContainer: "#customize-controls",
         responsive: {
         640: {                
            gutter: 6,
            items: 2
         },
         700: {
            gutter: 10
         },
         900: {
            items: 4
         }
         }
      });
   });

    return(
        <>
        <div className="slider-container position-relative">
               <ul className="controls" id="customize-controls" aria-label="Carousel Navigation" tabindex="0">
                  <li className="prev" data-controls="prev" aria-controls="customize" tabindex="-1">
                     <i className="fas fa-angle-left fa-5x"></i>
                  </li>
                  <li className="next" data-controls="next" aria-controls="customize" tabindex="-1">
                     <i className="fas fa-angle-right fa-5x"></i>          
                  </li>
               </ul>
               <div className="my-slider">
                  <div className="slider-item">
                     <div className="card border border-2 border-dashed h-150px shadow-none d-flex align-items-center justify-content-center text-center">
                        <div>
                           <a className="stretched-link btn btn-light rounded-circle icon-md" href="#!"><i className="fa-solid fa-plus"></i></a>
                           <h6 className="mt-2 mb-0 small">Post a Story</h6>
                        </div>
                     </div>
                  </div>
                  <div className="slider-item">
                     <div className="card card-overlay-bottom border-0 position-relative h-150px" 
                        style={{ backgroundImage: `url("assets/images/post/1by1/02.jpg")`,
                        backgroundSize:"cover",
                        backgroundPosition:"center left" }}
                     >
                        <div className="card-img-overlay d-flex align-items-center p-2">
                           <div className="w-100 mt-auto">
                              <a href="#!" className="stretched-link text-white small">Judy Nguyen</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="slider-item">
                  <div className="card card-overlay-bottom border-0 position-relative h-150px" 
                        style={{ backgroundImage: `url("assets/images/post/1by1/03.jpg")`,
                        backgroundSize:"cover",
                        backgroundPosition:"center left" }}
                     >
                        <div className="card-img-overlay d-flex align-items-center p-2">
                           <div className="w-100 mt-auto">
                              <a href="#!" className="stretched-link text-white small">Samuel Bishop</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="slider-item">
                  <div className="card card-overlay-bottom border-0 position-relative h-150px" 
                        style={{ backgroundImage: `url("assets/images/post/1by1/04.jpg")`,
                        backgroundSize:"cover",
                        backgroundPosition:"center left" }}
                     >
                        <div className="card-img-overlay d-flex align-items-center p-2">
                           <div className="w-100 mt-auto">
                              <a href="#!" className="stretched-link text-white small">Carolyn Ortiz</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="slider-item">
                  <div className="card card-overlay-bottom border-0 position-relative h-150px" 
                        style={{ backgroundImage: `url("assets/images/post/1by1/05.jpg")`,
                        backgroundSize:"cover",
                        backgroundPosition:"center left" }}
                     >
                        <div className="card-img-overlay d-flex align-items-center p-2">
                           <div className="w-100 mt-auto">
                              <a href="#!" className="stretched-link text-white small">Amanda Reed</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="slider-item">
                  <div className="card card-overlay-bottom border-0 position-relative h-150px" 
                        style={{ backgroundImage: `url("assets/images/post/1by1/05.jpg")`,
                        backgroundSize:"cover",
                        backgroundPosition:"center left" }}
                     >
                        <div className="card-img-overlay d-flex align-items-center p-2">
                           <div className="w-100 mt-auto">
                              <a href="#!" className="stretched-link text-white small">Lori Stevens</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        </>
    )
}

export default FriendsSlider;