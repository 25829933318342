
const NotFoundPage = () => {
    return(
        <>


<main class="py-5">
  <div class="container">
    {/* <div class="h-100px d-none d-lg-block"></div> */}
    <div class="row align-items-center text-center py-sm-5">
      <div class="col-lg-8 mx-auto">  
        <div className="row">
            <div class="col-lg-5"> 
                <img src="assets/images/img/page_not_found.png" className="img-fluid"  />
            </div> 
            <div class="col-lg-7"> 
                <h1 class="display-1 mt-4" style={{fontSize:"210px"}}>404</h1>
                <h2 class="mb-2 h1">Page Not Found!</h2>
                <p>Either something went wrong or this page doesn't exist anymore.</p>
                <a class="btn btn-primary-soft btn-sm" href="/">Got to home page</a>
            </div> 
        </div>      
        
      </div>
    </div> 
    <div class="h-100px d-none d-lg-block"></div>
  </div>
</main>



        </>
    )
}

export default NotFoundPage;