
const ShareBox = () =>{
    return(
        <>
        <div className="card card-body">
            <div className="d-flex mb-3">
                <div className="avatar avatar-xs me-2">
                    <a href="#"> <img className="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt="" /> </a>
                </div>
                <form className="w-100">
                    <textarea className="form-control pe-4 border-0" rows="2" data-autoresize placeholder="Share your thoughts..."></textarea>
                </form>
            </div>
            <ul className="nav nav-pills nav-stack small fw-normal">
                <li className="nav-item">
                    <a className="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal" data-bs-target="#feedActionPhoto"> <i className="bi bi-image-fill text-success pe-2"></i>Photo</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal" data-bs-target="#feedActionVideo"> <i className="bi bi-camera-reels-fill text-info pe-2"></i>Video</a>
                </li>
                <li className="nav-item">
                    <a href="#" className="nav-link bg-light py-1 px-2 mb-0" data-bs-toggle="modal" data-bs-target="#modalCreateEvents"> <i className="bi bi-calendar2-event-fill text-danger pe-2"></i>Event </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal" data-bs-target="#modalCreateFeed"> <i className="bi bi-emoji-smile-fill text-warning pe-2"></i>Feeling /Activity</a>
                </li>
                <li className="nav-item dropdown ms-lg-auto">
                    <a className="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-three-dots"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                    <li><a className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                    <li><a className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question </a></li>
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <li><a className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
                    </ul>
                </li>
            </ul>
        </div>
        <div className="modal fade" id="modalCreateFeed" tabindex="-1" aria-labelledby="modalLabelCreateFeed" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="modalLabelCreateFeed">Create post</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className="d-flex mb-3">
                <div className="avatar avatar-xs me-2">
                    <img className="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt=""/>
                </div>
                <form className="w-100">
                    <textarea className="form-control pe-4 fs-3 lh-1 border-0" rows="4" placeholder="Share your thoughts..." autofocus></textarea>
                </form>
                </div>
                <div className="hstack gap-2">
                <a className="icon-md bg-success bg-opacity-10 text-success rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Photo"> <i className="bi bi-image-fill"></i> </a>
                <a className="icon-md bg-info bg-opacity-10 text-info rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Video"> <i className="bi bi-camera-reels-fill"></i> </a>
                <a className="icon-md bg-danger bg-opacity-10 text-danger rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Events"> <i className="bi bi-calendar2-event-fill"></i> </a>
                <a className="icon-md bg-warning bg-opacity-10 text-warning rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Feeling/Activity"> <i className="bi bi-emoji-smile-fill"></i> </a>
                <a className="icon-md bg-light text-secondary rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Check in"> <i className="bi bi-geo-alt-fill"></i> </a>
                <a className="icon-md bg-primary bg-opacity-10 text-primary rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Tag people on top"> <i className="bi bi-tag-fill"></i> </a>
                </div>
            </div>

            <div className="modal-footer row justify-content-between">
                <div className="col-lg-3">
                <select className="form-select js-choice choice-select-text-none" data-position="top" data-search-enabled="false">
                    <option value="PB">Public</option>
                    <option value="PV">Friends</option>
                    <option value="PV">Only me</option>
                    <option value="PV">Custom</option>
                </select>
                </div>
                <div className="col-lg-8 text-sm-end">
                <button type="button" className="btn btn-danger-soft me-2"> <i className="bi bi-camera-video-fill pe-1"></i> Live video</button>
                <button type="button" className="btn btn-success-soft">Post</button>
                </div>
            </div>

            </div>
        </div>
        </div>
        <div className="modal fade" id="feedActionPhoto" tabindex="-1" aria-labelledby="feedActionPhotoLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="feedActionPhotoLabel">Add post photo</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                <div className="modal-body">
                <div className="d-flex mb-3">
                <div className="avatar avatar-xs me-2">
                    <img className="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt="" />
                </div>
                <form className="w-100">
                    <textarea className="form-control pe-4 fs-3 lh-1 border-0" rows="2" placeholder="Share your thoughts..."></textarea>
                </form>
                </div>
                <div>
                <label className="form-label">Upload attachment</label>
                <div className="dropzone dropzone-default card shadow-none" data-dropzone='{"maxFiles":2}'>
                    <div className="dz-message">
                    <i className="bi bi-images display-3"></i>
                    <p>Drag here or click to upload photo.</p>
                    </div>
                </div>
                </div>
                </div>
                <div className="modal-footer ">
                    <button type="button" className="btn btn-danger-soft me-2" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" className="btn btn-success-soft">Post</button>
                </div>
            </div>
        </div>
        </div>
        <div className="modal fade" id="feedActionVideo" tabindex="-1" aria-labelledby="feedActionVideoLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
            <h5 className="modal-title" id="feedActionVideoLabel">Add post video</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className="d-flex mb-3">
                <div className="avatar avatar-xs me-2">
                <img className="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt="" />
                </div>
                <form className="w-100">
                <textarea className="form-control pe-4 fs-3 lh-1 border-0" rows="2" placeholder="Share your thoughts..."></textarea>
                </form>
            </div>

            <div>
                <label className="form-label">Upload attachment</label>
                <div className="dropzone dropzone-default card shadow-none" data-dropzone='{"maxFiles":2}'>
                <div className="dz-message">
                    <i className="bi bi-camera-reels display-3"></i>
                        <p>Drag here or click to upload video.</p>
                </div>
                </div>
            </div>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-danger-soft me-2"><i className="bi bi-camera-video-fill pe-1"></i> Live video</button>
                <button type="button" className="btn btn-success-soft">Post</button>
            </div>
            </div>
        </div>
        </div>
        <div className="modal fade" id="modalCreateEvents" tabindex="-1" aria-labelledby="modalLabelCreateAlbum" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="modalLabelCreateAlbum">Create event</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <form className="row g-4">
                <div className="col-12">
                    <label className="form-label">Title</label>
                    <input type="email" className="form-control" placeholder="Event name here"/>
                </div>
                <div className="col-12">
                    <label className="form-label">Description</label>
                    <textarea className="form-control" rows="2" placeholder="Ex: topics, schedule, etc."></textarea>
                </div>
                <div className="col-sm-4">
                    <label className="form-label">Date</label>
                    <input type="text" className="form-control flatpickr" placeholder="Select date"/>
                </div>
                <div className="col-sm-4">
                    <label className="form-label">Time</label>
                    <input type="text" className="form-control flatpickr" data-enableTime="true" data-noCalendar="true" placeholder="Select time"/>
                </div>
                <div className="col-sm-4">
                    <label className="form-label">Duration</label>
                    <input type="email" className="form-control" placeholder="1hr 23m"/>
                </div>
                <div className="col-12">
                    <label className="form-label">Location</label>
                    <input type="email" className="form-control" placeholder="Logansport, IN 46947"/>
                </div>
                <div className="col-12">
                    <label className="form-label">Add guests</label>
                    <input type="email" className="form-control" placeholder="Guest email"/>
                </div>
                <div className="col-12 mt-3">
                    <ul className="avatar-group list-unstyled align-items-center mb-0">
                    <li className="avatar avatar-xs">
                        <img className="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="avatar"/>
                    </li>
                    <li className="avatar avatar-xs">
                        <img className="avatar-img rounded-circle" src="assets/images/avatar/02.jpg" alt="avatar"/>
                    </li>
                    <li className="avatar avatar-xs">
                        <img className="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt="avatar"/>
                    </li>
                    <li className="avatar avatar-xs">
                        <img className="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt="avatar"/>
                    </li>
                    <li className="avatar avatar-xs">
                        <img className="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt="avatar"/>
                    </li>
                    <li className="avatar avatar-xs">
                        <img className="avatar-img rounded-circle" src="assets/images/avatar/06.jpg" alt="avatar"/>
                    </li>
                    <li className="avatar avatar-xs">
                        <img className="avatar-img rounded-circle" src="assets/images/avatar/07.jpg" alt="avatar"/>
                    </li>
                    <li className="ms-3">
                        <small> +50 </small>
                    </li>
                    </ul>
                </div>
                <div>
                    <label className="form-label">Upload attachment</label>
                    <div className="dropzone dropzone-default card shadow-none" data-dropzone='{"maxFiles":2}'>
                    <div className="dz-message">
                        <i className="bi bi-file-earmark-text display-3"></i>
                        <p>Drop presentation and document here or click to upload.</p>
                    </div>
                    </div>
                </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Cancel</button>
                <button type="button" className="btn btn-success-soft">Create now</button>
            </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default ShareBox;